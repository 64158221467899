import React from "react";
import { FAQLinks } from "../../../constants/app-links";

export default class CoverageQuestions {

  static whoIsTheInsuranceCarrier = () => {
    return (
      <div className="faqAnswer">
        <p>Your insurance company is one of the following Chubb companies:</p>
        <p>
         Policies are underwritten and issued by ACE American 
         Insurance Company, Westchester Fire Insurance Company, Indemnity 
         Insurance Company of North America, ACE Property & Casualty 
         Insurance Company, Atlantic Employers Insurance Company, members 
         of the Chubb Group.
        </p>

      </div>
    );
  };

  static whatIsCoveredAnswer = (policyFormUrl, stateAbbreviation) => {

    var content = '';

    switch (stateAbbreviation) {
      case 'DE':
        content = `<p>The Healthy Paws pet insurance plan covers the unexpected things that come up with pet health care, whether that is an accident or illness. If your pet gets sick or injured, the Healthy Paws plan can help, subject to the policy's terms and conditions. The pet insurance plan has no caps on payouts: no per-incident caps, no annual caps, and no lifetime caps.</p>
          <p>If your pet shows signs or symptoms of a new accident or illness after enrollment and after any applicable waiting period, it will be eligible for coverage. The pet insurance plan covers medically necessary veterinary treatments, including but not limited to:</p>
          <ul class="blackdisc">
              <li>Diagnostic testing such as x-rays and blood tests</li>
              <li>Hospitalization, including any treatments while in the hospital</li>
              <li>Surgeries</li>
              <li>Prescription medications</li>
          </ul>
          <p style="font-style: italic;">You may use any licensed veterinarian.</p>
          <p>A veterinarian is defined as a properly licensed and registered veterinarian in active practice in the area where the pet is treated or examined. Veterinarian shall not include you or a member of your immediate family.</p>
          <p>
              <a class="orange underline" style="font-weight:600"
              href="` + process.env.REACT_APP_MAINSITE_URL + `/PolicyDocuments/StateDocuments/State/DE/Sample - PI-57983_1023_DE WFIC Amendatory Endorsement.pdf"
              target="_blank">
              Refer to the State Amendatory Statement for your state for more information.
              </a>
          </p>`;
          break;
      case 'LA':
        content = `<p>The Healthy Paws pet insurance plan covers the unexpected things that come up with pet health care, whether that is an accident or illness. If your pet gets sick or injured, the Healthy Paws plan can help, subject to the policy's terms and conditions. The pet insurance plan has no caps on payouts: no per-incident caps, no annual caps, and no lifetime caps.</p>
          <p>If your pet shows signs or symptoms of a new accident or illness after enrollment and after any applicable waiting period, it will be eligible for coverage. The pet insurance plan covers medically necessary veterinary treatments, including but not limited to:</p>
          <ul class="blackdisc">
              <li>Diagnostic testing such as x-rays and blood tests
              <li>Hospitalization, including any treatments while in the hospital
              <li>Surgeries
              <li>Prescription medications
          </ul>
          <p style="font-style: italic;">You may use any licensed veterinarian.</p>
          <p>A veterinarian is defined as a properly licensed and registered veterinarian in active practice in the area where the pet is treated or examined. Veterinarian shall not include you or a member of your immediate family.</p>
          <p>
              <a class="orange underline" style="font-weight:600"
              href="` + process.env.REACT_APP_MAINSITE_URL + `/PolicyDocuments/StateDocuments/State/LA/Sample - PI-57956_1023_Amendatory Endorsement.pdf"
              target="_blank">
              Refer to the State Amendatory Statement for your state for more information.
              </a>
          </p>`;
          break;
      case 'ME':
        content = `<p>The Healthy Paws pet insurance plan covers the unexpected things that come up with pet health care, whether that is an accident or illness. If your pet gets sick or injured, the Healthy Paws plan can help, subject to the policy's terms and conditions. The pet insurance plan has no caps on payouts: no per-incident caps, no annual caps, and no lifetime caps.</p>
          <p>If your pet shows signs or symptoms of a new accident or illness after enrollment and after any applicable waiting period, it will be eligible for coverage. The pet insurance plan covers medically necessary veterinary treatments, including but not limited to:</p>
          <ul class="blackdisc">
              <li>Diagnostic testing such as x-rays and blood tests
              <li>Hospitalization, including any treatments while in the hospital
              <li>Surgeries
              <li>Prescription medications
          </ul>
          <p style="font-style: italic;">You may use any licensed veterinarian.</p>
          <p>A veterinarian is defined as a properly licensed and registered veterinarian in active practice in the area where the pet is treated or examined. Veterinarian shall not include you or a member of your immediate family.</p>
          <p>
              <a class="orange underline" style="font-weight:600"
              href="` + process.env.REACT_APP_MAINSITE_URL + `/PolicyDocuments/StateDocuments/State/ME/LD-50780_1022_WFIC Maine Amendatory_SAMPLE.pdf"
              target="_blank">
              Refer to the State Amendatory Statement for your state for more information.
              </a>
          </p>`;
          break;
      case 'MS':
        content = `<p>The Healthy Paws pet insurance plan covers the unexpected things that come up with pet health care, whether that is an accident or illness. If your pet gets sick or injured, the Healthy Paws plan can help, subject to the policy's terms and conditions. The pet insurance plan has no caps on payouts: no per-incident caps, no annual caps, and no lifetime caps.</p>
          <p>If your pet shows signs or symptoms of a new accident or illness after enrollment and after any applicable waiting period, it will be eligible for coverage. The pet insurance plan covers medically necessary veterinary treatments, including but not limited to:</p>
          <ul class="blackdisc">
              <li>Diagnostic testing such as x-rays and blood tests
              <li>Hospitalization, including any treatments while in the hospital
              <li>Surgeries
              <li>Prescription medications
          </ul>
          <p style="font-style: italic;">You may use any licensed veterinarian.</p>
          <p>A veterinarian is defined as a properly licensed and registered veterinarian in active practice in the area where the pet is treated or examined. Veterinarian shall not include you or a member of your immediate family.</p>
          <p>
              <a class="orange underline" style="font-weight:600"
              href="` + process.env.REACT_APP_MAINSITE_URL + `/PolicyDocuments/StateDocuments/State/MS/Sample - PI-57955_0923_Amendatory Endorsement.pdf"
              target="_blank">
              Refer to the State Amendatory Statement for your state for more information.
              </a>
          </p>`;
        break;
      case 'NE':
        content = `<p>The Healthy Paws pet insurance plan covers the unexpected things that come up with pet health care, whether that is an accident or illness. If your pet gets sick or injured, the Healthy Paws plan can help, subject to the policy's terms and conditions. The pet insurance plan has no caps on payouts: no per-incident caps, no annual caps, and no lifetime caps.</p>
          <p>If your pet shows signs or symptoms of a new accident or illness after enrollment and after any applicable waiting period, it will be eligible for coverage. The pet insurance plan covers medically necessary veterinary treatments, including but not limited to:</p>
          <ul class="blackdisc">
              <li>Diagnostic testing such as x-rays and blood tests
              <li>Hospitalization, including any treatments while in the hospital
              <li>Surgeries
              <li>Prescription medications
          </ul>
          <p style="font-style: italic;">You may use any licensed veterinarian.</p>
          <p>A veterinarian is defined as a properly licensed and registered veterinarian in active practice in the area where the pet is treated or examined. Veterinarian shall not include you or a member of your immediate family.</p>
          <p>
              <a class="orange underline" style="font-weight:600"
              href="` + process.env.REACT_APP_MAINSITE_URL + `/PolicyDocuments/StateDocuments/State/NE/Sample - LD-50785_0823_NE 3.0 - Amendatory Endorsement.pdf"
              target="_blank">
              Refer to the State Amendatory Statement for your state for more information.
              </a>
          </p>`;
        break;
      case 'NH':
        content = `<p>The Healthy Paws pet insurance plan covers the unexpected things that come up with pet health care, whether that is an accident or illness. If your pet gets sick or injured, the Healthy Paws plan can help, subject to the policy's terms and conditions. The pet insurance plan has no caps on payouts: no per-incident caps, no annual caps, and no lifetime caps.</p>
          <p>If your pet shows signs or symptoms of a new accident or illness after enrollment and after any applicable waiting period, it will be eligible for coverage. The pet insurance plan covers medically necessary veterinary treatments, including but not limited to:</p>
          <ul class="blackdisc">
              <li>Diagnostic testing such as x-rays and blood tests
              <li>Hospitalization, including any treatments while in the hospital
              <li>Surgeries
              <li>Prescription medications
          </ul>
          <p style="font-style: italic;">You may use any licensed veterinarian.</p>
          <p>A veterinarian is defined as a properly licensed and registered veterinarian in active practice in the area where the pet is treated or examined. Veterinarian shall not include you or a member of your immediate family.</p>
          <p>
              <a class="orange underline" style="font-weight:600"
              href="` + process.env.REACT_APP_MAINSITE_URL + `/PolicyDocuments/StateDocuments/State/NH/Sample - LD-50787_0923_Amendatory Endorsement.pdf"
              target="_blank">
              Refer to the State Amendatory Statement for your state for more information.
              </a>
          </p>`;
        break;
      case 'WA':
        content = `<p>The Healthy Paws pet insurance plan covers the unexpected things that come up with pet health care, whether that is an accident or illness. If your pet gets sick or injured, the Healthy Paws plan can help, subject to the policy's terms and conditions. The pet insurance plan has no caps on payouts: no per-incident caps, no annual caps, and no lifetime caps.</p>
          <p>If your pet shows signs or symptoms of a new accident or illness after enrollment and after any applicable waiting period, it will be eligible for coverage. The pet insurance plan covers medically necessary veterinary treatments, including but not limited to:</p>
          <ul class="blackdisc">
              <li>Diagnostic testing such as x-rays and blood tests
              <li>Hospitalization, including any treatments while in the hospital
              <li>Surgeries
              <li>Prescription medications
          </ul>
          <p style="font-style: italic;">You may use any licensed veterinarian.</p>
          <p>A veterinarian is defined as a properly licensed and registered veterinarian in active practice in the area where the pet is treated or examined. Veterinarian shall not include you or a member of your immediate family.</p>
          <p>
              <a class="orange underline" style="font-weight:600"
              href="` + process.env.REACT_APP_MAINSITE_URL + `/PolicyDocuments/StateDocuments/State/WA/Sample - LD-50798_0923_WFIC Amendatory Endorsement.pdf"
              target="_blank">
              Refer to the State Amendatory Statement for your state for more information.
              </a>
          </p>`;
          break;
      case 'CA':
        content = `<p>The Healthy Paws pet insurance plan covers the unexpected things that come up with pet health care, whether that is an accident or illness. If your pet gets sick or injured, the Healthy Paws plan can help, subject to the policy's terms and conditions. The pet insurance plan has no caps on payouts: no per-incident caps, no annual caps, and no lifetime caps.</p>
          <p>If your pet shows signs or symptoms of a new accident or illness after enrollment and after any applicable waiting period, it will be eligible for coverage. The pet insurance plan covers medically necessary veterinary treatments, including but not limited to:</p>
          <ul class="blackdisc">
              <li>Diagnostic testing such as x-rays and blood tests
              <li>Hospitalization, including any treatments while in the hospital
              <li>Surgeries
              <li>Prescription medications
          </ul>
          <p style="font-style: italic;">You may use any licensed veterinarian.</p>
          <p>A veterinarian is defined as a properly licensed and registered veterinarian in active practice in the area where the pet is treated or examined. Veterinarian shall not include you or a member of your immediate family.</p>
          <p>
              <a class="orange underline" style="font-weight:600"
              href="` + process.env.REACT_APP_MAINSITE_URL + `/FiledDocuments/CA 3.0 Amendatory Endorsement Doc.pdf"
              target="_blank">
              Refer to the State Amendatory Statement for your state for more information.
              </a>
          </p>`;
        break;
      default:
        content = `<p>
              The Healthy Paws pet insurance plan covers the unexpected things that come up with pet health care, whether that is an accident or illness. If your pet gets sick or injured, the Healthy Paws plan can help, subject to the policy's terms and conditions. The pet insurance plan has no caps on payouts: no per-incident caps, no annual caps, and no lifetime caps.
          </p>
          <p>
              If your pet shows signs or symptoms of a new accident or illness after enrollment and after any applicable waiting period, it will be eligible for coverage. The pet insurance plan covers medically necessary veterinary treatments, including but not limited to:
          </p>
          <ul class="blackdisc">
              <li>Diagnostic testing such as x-rays and blood tests</li>
              <li>Hospitalization, including any treatments while in the hospital</li>
              <li>Surgeries</li>
              <li>Prescription medications</li>
          </ul>
          <p style="font-style: italic;">
              You may use any licensed veterinarian.
          </p>`;
        break;
    } 

    return (
      <div className="faqAnswer" dangerouslySetInnerHTML={{ __html: content }}></div>
    );
  };

    static doYouHaveWaitingPeriod = (policyFormUrl, stateAbbreviation) => {

      var content = '';
      var policyFormLink = '';
      var faqPickerUrl = FAQLinks.FAQPicker;

      if (policyFormUrl) {
        policyFormLink = `<a style="font-weight:600" href="` + policyFormUrl + `" target="_blank" rel="noopener noreferrer">
            To review your policy, please click here.
          </a>`
      } else {
        policyFormLink = `<a style="font-weight:600" href="` + faqPickerUrl + `" target="_blank" rel="noopener noreferrer">
            To review your policy, please click here.
          </a>`
      }  

      switch (stateAbbreviation) {
        case 'DE':
          content = `<p>There is a 15-day waiting period from the pet policy effective date for illness, injury and orthopedic conditions (other than hip dysplasia) not resulting from an accident. However, this waiting period will be waived by us upon completion of a complete clinical examination. There shall be no waiting periods for illness, injury or orthopedic conditions resulting from an accident.</p>
            <p>
                <a class="orange underline" style="font-weight:600"
                href="` + process.env.REACT_APP_MAINSITE_URL + `/PolicyDocuments/StateDocuments/State/DE/Sample - PI-57983_1023_DE WFIC Amendatory Endorsement.pdf"
                target="_blank">
                Refer to the State Amendatory Statement for your state for more information.
                </a>
            </p>
            <p>${policyFormLink}</p>
            <p>Hip Dysplasia:</p>
            <p>There is a 30-day waiting period from the pet policy effective date for hip dysplasia not resulting from an accident.  However, this waiting period will be waived by us upon completion of a complete clinical examination.</p>
            <p>${policyFormLink}</p>`;
          break;
          case 'LA':
            content = `<p>There is a 15-day waiting period from the pet policy effective date for illness, injury and orthopedic conditions (other than hip dysplasia) not resulting from an accident. However, this waiting period will be waived by us upon completion of a complete clinical examination. There shall be no waiting periods for illness, injury or orthopedic conditions resulting from an accident.</p>
              <p>
                  <a class="orange underline" style="font-weight:600"
                  href="` + process.env.REACT_APP_MAINSITE_URL + `/PolicyDocuments/StateDocuments/State/LA/Sample - PI-57956_1023_Amendatory Endorsement.pdf"
                  target="_blank">
                  Refer to the State Amendatory Statement for your state for more information.
                  </a>
              </p>
              <p>${policyFormLink}</p>
              <p>Hip Dysplasia:</p>
              <p>There is a 30-day waiting period from the pet policy effective date for hip dysplasia not resulting from an accident.  However, this waiting period will be waived by us upon completion of a complete clinical examination.</p>
              <p>${policyFormLink}</p>`;
            break;
          case 'ME':
            content = `<p>There is a 15-day waiting period from the pet policy effective date for illness, injury and orthopedic conditions (other than hip dysplasia) not resulting from an accident. However, this waiting period will be waived by us upon completion of a complete clinical examination. There shall be no waiting periods for illness, injury or orthopedic conditions resulting from an accident.</p>
              <p>
                  <a class="orange underline" style="font-weight:600"
                  href="` + process.env.REACT_APP_MAINSITE_URL + `/PolicyDocuments/StateDocuments/State/ME/LD-50780_1022_WFIC Maine Amendatory_SAMPLE.pdf"
                  target="_blank">
                  Refer to the State Amendatory Statement for your state for more information.
                  </a>
              </p>
              <p>${policyFormLink}</p>
              <p>Hip Dysplasia:</p>
              <p>There is a 30-day waiting period from the pet policy effective date for hip dysplasia not resulting from an accident.  However, this waiting period will be waived by us upon completion of a complete clinical examination.</p>
              <p>${policyFormLink}</p>`;
            break;
          case 'MD':
            content = `<p>Waiting periods do not apply to the state of Maryland.</p>
                <p>${policyFormLink}</p>`
            break;
          case 'MS':
            content = `<p>There is a 15-day waiting period from the pet policy effective date for illness, injury and orthopedic conditions (other than hip dysplasia) not resulting from an accident. However, this waiting period will be waived by us upon completion of a complete clinical examination. There shall be no waiting periods for illness, injury or orthopedic conditions resulting from an accident.</p>
              <p>
                  <a class="orange underline" style="font-weight:600"
                  href="` + process.env.REACT_APP_MAINSITE_URL + `/PolicyDocuments/StateDocuments/State/MS/Sample - PI-57955_0923_Amendatory Endorsement.pdf"
                  target="_blank">
                  Refer to the State Amendatory Statement for your state for more information.
                  </a>
              </p>
              <p>${policyFormLink}</p>
              <p>Hip Dysplasia:</p>
              <p>There is a 30-day waiting period from the pet policy effective date for hip dysplasia not resulting from an accident.  However, this waiting period will be waived by us upon completion of a complete clinical examination.</p>
              <p>${policyFormLink}</p>`;
            break;
          case 'NE':
            content = `<p>There is a 15-day waiting period from the pet policy effective date for illness, injury and orthopedic conditions (other than hip dysplasia) not resulting from an accident. However, this waiting period will be waived by us upon completion of a complete clinical examination. There shall be no waiting periods for illness, injury or orthopedic conditions resulting from an accident.</p>
              <p>
                  <a class="orange underline" style="font-weight:600"
                  href="` + process.env.REACT_APP_MAINSITE_URL + `/PolicyDocuments/StateDocuments/State/NE/Sample - LD-50785_0823_NE 3.0 - Amendatory Endorsement.pdf"
                  target="_blank">
                  Refer to the State Amendatory Statement for your state for more information.
                  </a>
              </p>
              <p>${policyFormLink}</p>
              <p>Hip Dysplasia:</p>
              <p>There is a 30-day waiting period from the pet policy effective date for hip dysplasia not resulting from an accident.  However, this waiting period will be waived by us upon completion of a complete clinical examination.</p>
              <p>${policyFormLink}</p>`;
            break;
          case 'NH':
            content = `<p>There is a 15-day waiting period from the pet policy effective date for illness, injury and orthopedic conditions (other than hip dysplasia) not resulting from an accident. However, this waiting period will be waived by us upon completion of a complete clinical examination. There shall be no waiting periods for illness, injury or orthopedic conditions resulting from an accident.</p>
              <p>
                  <a class="orange underline" style="font-weight:600"
                  href="` + process.env.REACT_APP_MAINSITE_URL + `/PolicyDocuments/StateDocuments/State/NH/Sample - LD-50787_0923_Amendatory Endorsement.pdf"
                  target="_blank">
                  Refer to the State Amendatory Statement for your state for more information.
                  </a>
              </p>
              <p>${policyFormLink}</p>
              <p>Hip Dysplasia:</p>
              <p>There is a 30-day waiting period from the pet policy effective date for hip dysplasia not resulting from an accident.  However, this waiting period will be waived by us upon completion of a complete clinical examination.</p>
              <p>${policyFormLink}</p>`;
            break;  
          case 'WA':
              content = `<p>There is a 15-day waiting period from the pet policy effective date for illness and orthopedic conditions (other than hip dysplasia) not resulting from an accident. However, this waiting period will be waived by us upon completion of a complete clinical examination. There shall be no waiting periods for illness or orthopedic conditions resulting from an accident.</p>
              <p>
                  <a class="orange underline" style="font-weight:600"
                  href="` + process.env.REACT_APP_MAINSITE_URL + `/PolicyDocuments/StateDocuments/State/WA/Sample - LD-50798_0923_WFIC Amendatory Endorsement.pdf"
                  target="_blank">
                  Refer to the State Amendatory Statement for your state for more information.
                  </a>
              </p>
              <p>${policyFormLink}</p>
              <p>Hip Dysplasia:</p>
              <p>There is a 30-day waiting period from the pet policy effective date for hip dysplasia not resulting from an accident.  However, this waiting period will be waived by us upon completion of a complete clinical examination.</p>
              <p>${policyFormLink}</p>`;
            break;
          case 'CA':
            content = `<p>There is a 15-day waiting period from the pet policy effective date for illness, injury and orthopedic conditions (other than hip dysplasia) not resulting from an accident. However, this waiting period will be waived by us upon completion of a complete clinical examination. There shall be no waiting periods for illness, injury or orthopedic conditions resulting from an accident.</p>
              <p>
                  <a class="orange underline" style="font-weight:600"
                  href="` + process.env.REACT_APP_MAINSITE_URL + `/FiledDocuments/CA 3.0 Amendatory Endorsement Doc.pdf"
                  target="_blank">
                  Refer to the State Amendatory Statement for your state for more information.
                  </a>
              </p>
              <p>${policyFormLink}</p>
              <p>Hip Dysplasia:</p>
              <p>There is a 30-day waiting period from the pet policy effective date for hip dysplasia not resulting from an accident.  However, this waiting period will be waived by us upon completion of a complete clinical examination.</p>
              <p>${policyFormLink}</p>`;
            break;
        default:
          content = `<p>There is a waiting period of 15 days after your policy effective date. Your policy is effective at 12:01 a.m. the day after you enroll your pet(s). You can submit claims for accidents and illnesses that occur after the 15-day waiting period.</p>
            <p>Hip Dysplasia: For pets enrolled at age five or below, there is a 12-month waiting period for hip dysplasia.</p>
            <p>${policyFormLink}</p>`;
          break;
      }
    return (
      <div className="faqAnswer" dangerouslySetInnerHTML={{ __html: content }}></div>
    );
  };

  static maximumLimitAnswer = (policyFormUrl) => {
    return (
      <div className="faqAnswer">
        <p>
        With a Healthy Paws pet insurance plan, you will have continuous coverage for
        the life of the policy, without the concern of any type of per-incident, annual or
        lifetime caps.
        </p>
        <p>
        { policyFormUrl ? (
            <a style={{fontWeight:"600"}} href={policyFormUrl} target="_blank" rel="noopener noreferrer">
            To review your policy, please click here.
            </a>
          ) : (
            <a style={{fontWeight:"600"}} href={FAQLinks.FAQPicker} target="_blank" rel="noopener noreferrer">
                            To review your policy, please click here.
            </a>
          )}
        </p>
      </div>
    );
  };

  static heridityConditionCovered = (policyFormUrl) => {
    return (
      <div className="faqAnswer">
        <ul className="blackdisc">
          <li>
            <span className="font-bold">Hereditary condition:&nbsp;</span> An abnormality that is genetically transmitted from
                 parent to offspring and may cause illness or disease.&nbsp;
                 <span style={{fontWeight:"bold"}}>Some examples: Bladder Stones, Epilepsy, Heart Disease,
                 Degenerative Myelopathy, Brachycephalic Syndrome</span>
          </li>
          <br/>
          <li>
            <span className="font-bold">Congenital condition:&nbsp;</span>
              A condition that is present from birth, whether inherited
              or caused by the environment, which may cause or contribute to illness or disease.&nbsp; 
              <span style={{fontWeight:"bold"}}>Some examples: Cleft Palate, Umbilical Hernia, Inguinal Hernia,
              Limb Deformities</span>
          </li>
        </ul>
        <p>
        Hereditary and congenital conditions are eligible for coverage so long as the
        signs and symptoms first manifest after enrolling and after any applicable
        waiting periods.
        </p>
        <p>
        { policyFormUrl ? (
            <a style={{fontWeight:"600"}} href={policyFormUrl} target="_blank" rel="noopener noreferrer">
            To review your policy, please click here.
            </a>
          ) : (
            <a style={{fontWeight:"600"}} href={FAQLinks.FAQPicker} target="_blank" rel="noopener noreferrer">
                            To review your policy, please click here.
            </a>
          )}
        </p>
      </div>
    );
  };

  static didYouCoverHipDysplasia = (policyFormUrl, stateAbbreviation) => {

    var content = '';
    var policyFormLink = '';
    var faqPickerUrl = FAQLinks.FAQPicker;

    if (policyFormUrl) {
      policyFormLink = `<a style="font-weight:600" href="` + policyFormUrl + `" target="_blank" rel="noopener noreferrer">
          To review your policy, please click here.
        </a>`
    } else {
      policyFormLink = `<a style="font-weight:600" href="` + faqPickerUrl + `" target="_blank" rel="noopener noreferrer">
          To review your policy, please click here.
        </a>`
    }  

    switch (stateAbbreviation) {
      case 'DE':
        content = `<p>Hip dysplasia is included in the coverage at no additional cost to you as long as the following requirements are met:</p>
          <ul class="blackdisc">
              <li>Your pet is under the age of six (6) at the time of enrollment.</li>
              <li>No clinical signs or symptoms manifest within the first 30 days from the pet policy effective date unless waived by us upon completion of a complete clinical examination.</li>
          </ul>
          <p>${policyFormLink}</p>`;
        break;
      case 'LA':
        content = `<p>Hip dysplasia is included in the coverage at no additional cost to you as long as the following requirements are met:</p>
          <ul class="blackdisc">
              <li>Your pet is under the age of six (6) at the time of enrollment.</li>
              <li>No clinical signs or symptoms manifest within the first 30 days from the pet policy effective date unless waived by us upon completion of a complete clinical examination.</li>
          </ul>
          <p>${policyFormLink}</p>`;
          break;
        case 'ME':
          content = `<p>Hip dysplasia is included in the coverage at no additional cost to you as long as the following requirements are met:</p>
            <ul class="blackdisc">
                <li>Your pet is under the age of six (6) at the time of enrollment.</li>
                <li>No clinical signs or symptoms manifest within the first 30 days from the pet policy effective date unless waived by us upon completion of a complete clinical examination.</li>
            </ul>
            <p>${policyFormLink}</p>`;
          break;
        case 'MD':
          content = `<p>Hip dysplasia is included in the coverage at no additional cost.</p>
            <p>${policyFormLink}</p>`;
          break;
        case 'MS':
          content = `<p>Hip dysplasia is included in the coverage at no additional cost to you as long as the following requirements are met:</p>
            <ul class="blackdisc">
                <li>Your pet is under the age of six (6) at the time of enrollment.</li>
                <li>No clinical signs or symptoms manifest within the first 30 days from the pet policy effective date unless waived by us upon completion of a complete clinical examination.</li>
            </ul>
            <p>${policyFormLink}</p>`;
          break;
        case 'NE':
          content = `<p>Hip dysplasia is included in the coverage at no additional cost to you as long as the following requirements are met:</p>
            <ul class="blackdisc">
                <li>Your pet is under the age of six (6) at the time of enrollment.</li>
                <li>No clinical signs or symptoms manifest within the first 30 days from the pet policy effective date unless waived by us upon completion of a complete clinical examination.</li>
            </ul>
            <p>${policyFormLink}</p>`;
          break;
        case 'NH':
          content = `<p>Hip dysplasia is included in the coverage at no additional cost to you as long as the following requirements are met:</p>
            <ul class="blackdisc">
                <li>Your pet is under the age of six (6) at the time of enrollment.</li>
                <li>No clinical signs or symptoms manifest within the first 30 days from the pet policy effective date unless waived by us upon completion of a complete clinical examination.</li>
            </ul>
            <p>${policyFormLink}</p>`;
          break;
        case 'WA':
        content = `<p>Hip dysplasia is included in the coverage at no additional cost to you as long as the following requirements are met:</p>
          <ul class="blackdisc">
              <li>Your pet is under the age of six (6) at the time of enrollment.</li>
              <li>No clinical signs or symptoms manifest within the first 30 days from the pet policy effective date unless waived by us upon completion of a complete clinical examination.</li>
          </ul>
          <p>${policyFormLink}</p>`;
        break;
        case 'CA':
          content = `<p>Hip dysplasia is included in the coverage at no additional cost to you as long as the following requirements are met:</p>
          <ul class="blackdisc">
              <li>Your pet is under the age of six (6) at the time of enrollment.</li>
              <li>No clinical signs or symptoms manifest within the first 30 days from the pet policy effective date unless waived by us upon completion of a complete clinical examination.</li>
          </ul>
          <p>${policyFormLink}</p>`;
        break;
      default:
        content = `<p>Hip dysplasia is included in the coverage at no additional cost to you as long as the following requirements are met:</p>
          <ul class="blackdisc">
              <li>Your pet is under the age of six (6) at the time of enrollment.</li>
              <li>No clinical signs or symptoms manifest within the first twelve (12) months from the Pet Policy Effective Date.</li>
          </ul>
          <p>${policyFormLink}</p>`
    }

    return (
      <div className="faqAnswer" dangerouslySetInnerHTML={{ __html: content }}></div>
    );
  };

  static whatIsNotCovered = (policyFormUrl) => {
    return (
      <div className="faqAnswer">
        <p>
          For a comprehensive list of policy exclusions and limitations, download a copy of
          the Healthy Paws Pet Insurance policy{" "}
          <a style={{fontWeight:"600"}} href={FAQLinks.FAQPicker} target="_blank" rel="noopener noreferrer">
            here
          </a>
          .
        </p>
        <p>
        The most common exclusions encountered during the claims review process are:
        </p>
        <ul className="blackdisc">
          <li>Wellness and preventative care</li>
          <li>Examination fees</li>
          <li>Pre-existing conditions</li>
          <li>Conditions occurring within policy waiting period(s)</li>
          <li>Dental healthcare</li>
          <li>Behavioral treatment</li>
          <li>Non-FDA treatment or supplements</li>
          <li>Pet diets</li>
          <li>Day care/boarding</li>
        </ul>
      </div>
    );
  };

  static wellness = (policyFormUrl) => {
    return (
      <div className="faqAnswer">
        <p>
          Wellness and preventative care are not covered by your Healthy Paws policy.
          The plan is here for unexpected veterinary care.
        </p>
        <p>Some examples of preventative care include:</p>
        <ul className="blackdisc">
          <li>
            Vaccinations or titer testing, flea control, heartworm medication,
            de-worming, nail trims, spaying/neutering and grooming
          </li>
          <li>Parasite control</li>
          <li>Dental healthcare including dental cleanings</li>
          <li>Annual fecal and heartworm testing</li>
        </ul>
        <p>
        { policyFormUrl ? (
            <a style={{fontWeight:"600"}} href={policyFormUrl} target="_blank" rel="noopener noreferrer">
            To review your policy, please click here.
            </a>
          ) : (
            <a style={{fontWeight:"600"}} href={FAQLinks.FAQPicker} target="_blank" rel="noopener noreferrer">
                            To review your policy, please click here.
            </a>
          )}
        </p>
      </div>
    );
  };

  static dontCoverOfficeVisit = (policyFormUrl) => {
    return (
      <div className="faqAnswer">
        <p>
         By making the office visit part of a pet parent's financial responsibility, we can
         offer the accident and illness plan with no caps on claim payouts at a more
         affordable monthly premium.
        </p>
        <p>
          All fees related to the professional opinion of a veterinarian are also excluded.
          <br />
          <span className="font-italic">
          (Some examples of charges include: Examination, Medical Progress Exam, Recheck,
           Consultation, Specialist Consult, Emergency Exam, Office Call)
          </span>
        </p>
        <p>
        { policyFormUrl ? (
            <a style={{fontWeight:"600"}} href={policyFormUrl} target="_blank" rel="noopener noreferrer">
            To review your policy, please click here.
            </a>
          ) : (
            <a style={{fontWeight:"600"}} href={FAQLinks.FAQPicker} target="_blank" rel="noopener noreferrer">
                            To review your policy, please click here.
            </a>
          )}
        </p>
      </div>
    );
  };

  static dentalHealth = () => {
    return (
      <div className="faqAnswer">
        <p>
         The pet insurance plan offers coverage for dental care related to traumatic injury, such
         as chipping a tooth. If injury to teeth is caused by an accident, we do cover the cost
         of extraction and/or reconstruction of damaged teeth.
        </p>
      </div>
    );
  };

  static rxDeits = () => {
    return (
      <div className="faqAnswer">
        <p>
         The plan <u>does not</u>{" "} cover the cost of food, including prescription or special
         allergen diets.
        </p>
      </div>
    );
  };

  static whatBreedToChoose = () => {
    return (
      <div className="faqAnswer">
        <p>
          If you have a purebred dog or cat, simply select the matching breed from the
          dropdown list. By beginning to type the breed, you can search the list quickly.
        </p>
        <p>
          If your pet is a crossbreed (usually one purebred dog bred to another purebred
          dog of a different breed), we have some of the most popular combinations
          represented in the breed list, including Puggle, Yorkiepoo, or Goldendoodle. If the
          crossbreed you're looking for is not represented on the list, choose "Mixed
          Breed" and the weight of your pet when fully grown.
        </p>
        <p>
          If your pet is a Mixed Breed (a mix of multiple breeds, or possibly you're not sure
          what breed your dog or cat is), you can select the predominant breed in their
          mix, for example, "Boxer Mix" or "Terrier Mix." If the mix is unknown or not represented on our list, select "Mixed Breed" and the weight of your pet when
          fully grown.
        </p>
        <p>
          Your pet’s medical records will be used to verify the breed selection
          you chose during enrollment.
        </p>
      </div>
    );
  };

  static euthanasiaBurial = () => {
    return (
      <div className="faqAnswer">
        <p>
         As fellow pet parents, we understand this can be one of the hardest parts of pet
         ownership. The Healthy Paws plan can cover the costs of euthanasia when it is
         medically necessary for a covered condition. Expenses for aftercare, such as
         cremation or burial, are not eligible for coverage.
        </p>
      </div>
    );
  };
}
